export const schoolEventGroupingVariants = {
  closed: {
    height: 0,
    opacity: 0.2,
  },
  open: {
    height: "auto",
    opacity: 1,
  },
};
