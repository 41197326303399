import { Button } from "components/button/Button.component";
import { Link } from "components/link/Link.component";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useEffect, useRef, useState } from "react";
import "./EventBubble.styles.scss";

export const EventBubble = ({
  heading,
  icon,
  copy,
  link,
  linkText,
  bubbleColor,
}) => {
  const windowWidth = useWindowWidth();
  const headingBlockRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(headingBlockRef?.current?.clientHeight);
  }, [windowWidth]);

  return (
    <article
      className="event-card"
      style={{
        "--bubbleColor": bubbleColor,
      }}
    >
      <div className="event-logo">
        <div className="logo-header" style={{ height: headerHeight }} />
        <Link
          to={link}
          newTab
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            icon={icon}
            style={{
              width: "100%",
              textDecoration: "underline",
              height: "inherit",
              color: "inherit",
              borderRadius: 0,
              backgroundColor: "rgba(var(--blackRGB), 0.05)",
              justifyContent: "center",
            }}
          >
            Sign up now
          </Button>
        </Link>
      </div>
      <div className="event-info">
        <h6
          ref={headingBlockRef}
          className="invert-color no-margin info-header"
        >
          {heading}:
        </h6>
        <p>
          {copy}
          <div
            className="event-info-button"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              icon={icon}
              style={{
                height: "inherit",
              }}
            >
              Sign up now
            </Button>
          </div>
        </p>
      </div>
    </article>
  );
};
