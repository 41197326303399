import React from "react";
import "./ImageHeading.styles.scss";

export const ImageHeading = ({ heading, children }) => {
  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      <div className="school-header-image-wrapper">{children}</div>
      <div
        style={{
          backgroundColor: "rgba(var(--blackRGB), 0.6)",
          position: "absolute",
          bottom: "0",
          width: "100%",
        }}
      >
        <h2
          className="layout invert-color no-margin"
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
        >
          {heading}
        </h2>
      </div>
    </div>
  );
};
