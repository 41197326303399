import { motion } from "framer-motion";
import React from "react";
import "./CircleNote.styles.scss";

export const CircleNote = ({ note, style, hasHoverAnimation }) => {
  return (
    <motion.div
      className="circle"
      style={style}
      whileHover={
        hasHoverAnimation && {
          backgroundColor: "var(--tertiary)",
          transition: { duration: 0.1, type: "tween" },
        }
      }
    >
      {note}
    </motion.div>
  );
};
