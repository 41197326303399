import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { CircleNote } from "components/page-specific-components/upcoming-events/circle-note/CircleNote.component";
import { EventGroupCard } from "components/page-specific-components/upcoming-events/event-group-card/EventGroupCard.component";
import { SchoolEventGrouping } from "components/page-specific-components/upcoming-events/school-event-grouping/SchoolEventGrouping.component";
import { SEO } from "components/seo/SEO.component";
import { AnimatePresence } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { useRelatedPages } from "hooks/relatedPages.hook";
import highSchoolImage from "images/jpg/upcoming_events_high-school.jpg";
import middleSchoolImage from "images/jpg/upcoming_events_middle-school.jpg";
import React, { useRef, useState } from "react";
import linksService from "services/links.service";
import { RELATED_PAGES_LABELS } from "components/constants";
import {
  CLICK_BELOW,
  GENERIC_HEADING,
  HIGH_SCHOOL_HEADING,
  HIGH_SCHOOL_INDEX,
  MIDDLE_SCHOOL_HEADING,
  MIDDLE_SCHOOL_INDEX,
  NO_SET,
} from "./UpcomingEvents.constants";

const UpcomingEvents = ({ pageContext }) => {
  const { hasSelectors, isNiche, isMiddleSchool, isHighSchool } = pageContext;
  const isIndividualSchool = isMiddleSchool || isHighSchool;

  const schoolEventGroupingRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(() => {
    if (isMiddleSchool) {
      return MIDDLE_SCHOOL_INDEX;
    } else if (isHighSchool) {
      return HIGH_SCHOOL_INDEX;
    } else {
      return NO_SET;
    }
  });
  const heading = (() => {
    if (isMiddleSchool) {
      return MIDDLE_SCHOOL_HEADING;
    } else if (isHighSchool) {
      return HIGH_SCHOOL_HEADING;
    } else {
      return NO_SET;
    }
  })();

  const schoolEventGroupings = [
    {
      heading: MIDDLE_SCHOOL_HEADING,
      image: middleSchoolImage,
      campusVisit: linksService.scheduleMiddleSchoolCampusVisit,
      openHouse: linksService.scheduleMiddleSchoolOpenHouse,
      hasCampusTour: true,
      hasCampusVisit: true,
      hasOpenHouse: true,
    },
    {
      heading: HIGH_SCHOOL_HEADING,
      image: highSchoolImage,
      campusVisit: linksService.scheduleHighSchoolCampusVisit,
      openHouse: linksService.scheduleHighSchoolOpenHouse,
      hasCampusTour: false,
      hasCampusVisit: false,
      hasOpenHouse: true,
    },
  ];

  useRelatedPages([
    { label: RELATED_PAGES_LABELS.ADMISSIONS, link: linksService.admissions() },
    {
      label: RELATED_PAGES_LABELS.INQUIRY_FORM,
      link: linksService.inquiryForm(),
    },
    {
      label: RELATED_PAGES_LABELS.HOW_TO_APPLY,
      link: linksService.howToApply(),
    },
  ]);

  return (
    <>
      <SEO title="Upcoming Events" />
      <main>
        <WhiteGrouping>
          <section className="fix-header-overlap">
            {isNiche || isIndividualSchool ? (
              <div className="layout">
                <h2>{`${heading} ${GENERIC_HEADING}`}</h2>
              </div>
            ) : (
              <ImageHeading heading={GENERIC_HEADING}>
                <StaticImage
                  src="../../images/jpg/upcoming_events_header.png"
                  alt="world vision event"
                  placeholder="blurred"
                  layout="constrained"
                  style={{
                    marginTop: "-6rem",
                  }}
                />
              </ImageHeading>
            )}
          </section>
          <section
            className="layout fix-header-overlap"
            style={{
              paddingTop: 0,
              marginBottom: "5rem",
            }}
          >
            {hasSelectors && (
              <div className="upcoming-events-wrapper">
                <CircleNote
                  note={CLICK_BELOW}
                  style={{
                    rotate: "-15deg",
                    top: "0rem",
                    left: "-4rem",
                  }}
                />
                {schoolEventGroupings.map((schoolEventGrouping, cardIndex) => (
                  <EventGroupCard
                    schoolEventGroupingRef={schoolEventGroupingRef}
                    eventInfo={schoolEventGrouping}
                    cardIndex={cardIndex}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                  />
                ))}
              </div>
            )}
            <AnimatePresence>
              {currentIndex !== NO_SET && (
                <SchoolEventGrouping
                  ref={schoolEventGroupingRef}
                  schoolEventGrouping={schoolEventGroupings[currentIndex]}
                  isMarketing={isNiche}
                  isIndividualSchool={isIndividualSchool}
                />
              )}
            </AnimatePresence>
          </section>
        </WhiteGrouping>
      </main>
    </>
  );
};

export default UpcomingEvents;
