import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useEffect } from "react";
import { NO_SET } from "modules/upcoming-events/UpcomingEvents.constants";
import "./EventGroupCard.styles.scss";

export const EventGroupCard = ({
  schoolEventGroupingRef,
  eventInfo,
  cardIndex,
  currentIndex,
  setCurrentIndex,
}) => {
  const windowWidth = useWindowWidth();
  const { heading, image } = eventInfo;
  const isSelected = cardIndex === currentIndex;

  const handleClick = () => {
    if (isSelected) {
      setCurrentIndex(NO_SET);
    } else {
      setCurrentIndex(cardIndex);
    }
  };

  useEffect(() => {
    if (windowWidth <= 1200) {
      if (schoolEventGroupingRef?.current && currentIndex !== NO_SET) {
        window.scrollTo({
          top: schoolEventGroupingRef?.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [schoolEventGroupingRef.current, currentIndex]);

  const createBorderStyle = (color) => `solid ${color} 0.4rem`;

  return (
    <div>
      <h4
        className="no-margin"
        style={{
          textDecoration: isSelected ? "underline" : "none",
        }}
      >
        {heading}
      </h4>
      <div
        aria-label="button"
        tabIndex={0}
        className="event-group-card-container"
        style={{
          border: isSelected
            ? createBorderStyle("var(--green)")
            : createBorderStyle("var(--transparent)"),
        }}
        onKeyPress={(e) => {
          if (["Enter"].includes(e.key)) {
            handleClick();
          }
        }}
        onClick={handleClick}
      >
        <img draggable={false} src={image} height="auto" />
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            backgroundColor: "rgba(var(--blackRGB), 0.85)",
            width: "100%",
            borderBottomLeftRadius: "1rem",
            borderBottomRightRadius: "1rem",
            padding: "1rem",
          }}
        >
          <h6 className="no-margin invert-color">
            {!isSelected ? "+ View Events" : "- Hide Events"}
          </h6>
        </div>
      </div>
    </div>
  );
};
