import { motion } from "framer-motion";
import React from "react";

import linksService from "services/links.service";
import { CAMPUS_TOUR, CAMPUS_VISIT, OPEN_HOUSE } from "../../../constants";
import { SVGIcons } from "../../../svg-wrapper/SvgWrapper.enum";
import { EventBubble } from "../event-bubble/EventBubble.component";
import { schoolEventGroupingVariants } from "./SchoolEventGrouping.variants";

export const SchoolEventGrouping = React.forwardRef(
  ({ schoolEventGrouping, isMarketing, isIndividualSchool }, ref) => {
    const {
      heading: groupHeading,
      campusVisit,
      openHouse,
      hasCampusTour,
      hasCampusVisit,
      hasOpenHouse,
    } = schoolEventGrouping;
    const events = [];

    if (hasCampusTour) {
      events.push({
        heading: CAMPUS_TOUR.HEADING,
        icon: SVGIcons.Tour,
        copy: CAMPUS_TOUR.COPY,
        link: linksService.scheduleVisit(),
        linkText: CAMPUS_TOUR.LINK_TEXT,
      });
    }
    if (hasCampusVisit) {
      events.push({
        heading: `${groupHeading} ${CAMPUS_VISIT.HEADING}`,
        icon: SVGIcons.Map,
        copy: CAMPUS_VISIT.GET_COPY(groupHeading),
        link: campusVisit(),
        linkText: CAMPUS_VISIT.GET_LINK_TEXT(groupHeading),
      });
    }
    if (hasOpenHouse) {
      events.push({
        heading: `${groupHeading} ${OPEN_HOUSE.HEADING}`,
        icon: SVGIcons.OpenDoor,
        copy: OPEN_HOUSE.COPY,
        link: openHouse(),
        linkText: OPEN_HOUSE.GET_LINK_TEXT(groupHeading),
      });
    }

    const bubbleColors = [
      "var(--darkBlueRGB)",
      "var(--tealRGB)",
      "var(--orangeRGB)",
    ];

    return (
      <motion.div
        ref={ref}
        variants={schoolEventGroupingVariants}
        initial="closed"
        animate={"open"}
        exit="closed"
        transition={{
          type: "tween",
          duration: isMarketing || isIndividualSchool ? 0 : 0.2,
        }}
        style={{
          display: "flex",
          gap: "2rem",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {events.map((event, index) => {
          const bubbleColorIndex = index % bubbleColors.length;
          return (
            <EventBubble
              heading={event.heading}
              icon={event.icon}
              copy={event.copy}
              link={event.link}
              linkText={event.linkText}
              bubbleColor={`rgba(${bubbleColors[bubbleColorIndex]}, 0.1)`}
            />
          );
        })}
      </motion.div>
    );
  }
);
